import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { BASE_URL, COLORS } from "../../constants";
import { Link, useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import "./style.css";
import { Badge, Modal } from "react-bootstrap";
import { useAuth } from "../../contexts/Authentication";
import { useState } from "react";

export default function Packages() {
    const { services } = useAuth();
    return (
        <Row className="mt-5">
            <h1 className="text-center">ფასები</h1>

            <Col
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 8, offset: 2 }}
                md={{ span: 5, offset: 1 }}
            >
                <IndividualPackage data={services?.[0]} />
            </Col>
            <Col
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 8, offset: 2 }}
                md={{ span: 5, offset: 0 }}
            >
                <IndividualPackage data={services?.[1]} />
                {/* <IndividualPackage
                    comingSoon={true}
                    data={{
                        _id: "in-out",
                        title: "შიგნიდან და გარედან",
                        originalPrice: 44,
                        price: 33,
                        currency: "GEL",
                        avgWashMinutes: 60,
                        status: "ACTIVE",
                    }}
                /> */}
            </Col>
        </Row>
    );
}

export function IndividualPackage({ className, hideFooter, comingSoon, data }) {
    const navigate = useNavigate();
    const { token } = useAuth();
    const [showModal, setShowModal] = useState(false);

    async function requestService() {
        try {
            setShowModal(true);
            await (
                await fetch(
                    `${BASE_URL}/services/request/66bdc79b151a5dac7867895c`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
            ).json();
        } catch (error) {
            console.log(error);
        }
    }

    if (!data) return;
    return (
        <>
            <Modal
                centered
                show={showModal}
                // fullscreen="sm-down"
                onHide={() => setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>შიგნიდან და გარედან</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        სამწუხაროდ ეს მომსახურება ამ ეტაპზე არ არის
                        ხელმისაწვდომი, მაგრამ ძალიან მალე დავამატებთ.
                    </p>
                    <p>
                        მანამდე კი, ისარგებლე მხოლოდ გარედან გარეცხვის
                        მომსახურებით 👇
                    </p>
                    <div className="d-grid gap-2 shadow-sm">
                        <Button
                            // size="md"
                            variant="primary"
                            style={{
                                backgroundColor: COLORS.PRIMARY.TURQOISE,
                                borderColor: COLORS.PRIMARY.TURQOISE,
                                // fontSize: "1.7rem",
                            }}
                            onClick={() => navigate("/order")}
                        >
                            <div className="lead">
                                გარედან გარეცხვის შეკვეთა
                            </div>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Card className={[className, "mt-3"]}>
                <Card.Header className="text-center fs-5">
                    {data?.title}
                    {/* TODO: Offer Countdown? */}
                </Card.Header>
                <Card.Body>
                    <ul
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        {comingSoon && (
                            <div className="overlay-coming-soon">
                                <div
                                    style={{
                                        color: "#FFF",
                                        fontWeight: "bold",
                                    }}
                                >
                                    მალე
                                </div>
                            </div>
                        )}
                        <li>📍 შენს მისამართზე მომსახურება</li>
                        {data?._id === "66bdc79b151a5dac7867895c" && (
                            <li>🧹 Მტვერსასრუტით გაწმენდა</li>
                        )}
                        {data?._id === "66bdc79b151a5dac7867895c" && (
                            <li>✨ Სალონის ხსნარებით დამუშავება</li>
                        )}
                        {data?._id === "66bdc79b151a5dac7867895c" && (
                            <li>🪄 Ხალიჩების გასუფთავება</li>
                        )}
                        <li>💧 ჰიდროფობიური ქაფი</li>
                        <li>⚫️ საბურავების გაშავება</li>
                        <li>🧽 ავტომობილის გაშრობა</li>
                    </ul>
                    <hr
                        style={{
                            marginTop: 15,
                            marginBottom: 15,
                        }}
                    />
                    <div
                        style={{
                            // textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            columnGap: 10,
                        }}
                    >
                        <div
                            style={{
                                fontSize: 30,
                                // color: "#198754",
                            }}
                        >
                            {data.price}₾
                        </div>
                        {data.originalPrice !== data.price && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    // gap: 8,
                                }}
                            >
                                <Badge
                                    bg="success"
                                    pill
                                    style={{ fontSize: "13px" }}
                                >
                                    -{data.originalPrice - data.price}₾
                                </Badge>
                                <s
                                    style={{
                                        textDecorationThickness: "1.5px",
                                        fontSize: 18,
                                    }}
                                >
                                    {data.originalPrice}₾
                                </s>
                            </div>
                        )}
                    </div>
                </Card.Body>
                {!hideFooter && !comingSoon && (
                    <Card.Footer>
                        <div className="d-grid gap-2 shadow-sm">
                            <Button
                                // size="md"
                                variant="primary"
                                style={{
                                    backgroundColor: COLORS.PRIMARY.TURQOISE,
                                    borderColor: COLORS.PRIMARY.TURQOISE,
                                    // fontSize: "1.7rem",
                                }}
                                onClick={() =>
                                    navigate(`/order?serviceId=${data._id}`)
                                }
                            >
                                <div className="lead">გამოიძახე 🚀</div>
                            </Button>
                        </div>
                    </Card.Footer>
                )}
                {!hideFooter && comingSoon && (
                    <Card.Footer>
                        <div className="d-grid gap-2 shadow-sm">
                            <Button
                                // size="md"
                                variant="primary"
                                style={{
                                    backgroundColor: COLORS.PRIMARY.BLUE,
                                    borderColor: COLORS.PRIMARY.BLUE,
                                    position: "relative",
                                    zIndex: 10,
                                }}
                                onClick={() =>
                                    data._id === "in-out"
                                        ? requestService()
                                        : navigate(
                                              `/order?serviceId=${data._id}`
                                          )
                                }
                            >
                                <div
                                    className="lead"
                                    style={{
                                        fontSize: "16px",
                                    }}
                                >
                                    მოითხოვე მომსახურების დამატება
                                </div>
                            </Button>
                        </div>
                    </Card.Footer>
                )}
            </Card>
        </>
    );
}

export function OrderPackage({ className, isSelected, select, data }) {
    const [isOpen, setIsOpen] = useState(false);

    if (!data) return;
    return (
        <Card
            className={[
                className,
                "mt-3",
                isSelected ? "order-package-active" : "",
            ]}
        >
            <div
                role="button"
                onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
            >
                <Card.Header
                    className="text-center fs-5 d-flex justify-content-between"
                    data-toggle={isOpen}
                >
                    {/* TODO: Offer Countdown? */}
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flex: 1,
                            marginRight: 15,
                            // columnGap: 10,
                        }}
                    >
                        <div className="d-flex package-title align-items-center">
                            <Link
                                className="package-select-wrapper"
                                to="#"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    select();
                                    setIsOpen(false);
                                }}
                            >
                                <img
                                    className="package-select-icon"
                                    alt=""
                                    src={`/images/${
                                        isSelected ? "check" : "radio-off"
                                    }.svg`}
                                />
                            </Link>
                            {data?.title}
                        </div>
                        <div
                            style={{
                                // textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                columnGap: 10,
                            }}
                        >
                            <div
                                style={{
                                    fontSize: 20,
                                }}
                            >
                                {data.price}₾
                            </div>
                            {data.originalPrice !== data.price && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Badge
                                        bg="success"
                                        pill
                                        style={{ fontSize: 10 }}
                                    >
                                        -{data.originalPrice - data.price}₾
                                    </Badge>
                                    <s
                                        style={{
                                            textDecorationThickness: "1.5px",
                                            fontSize: 13,
                                        }}
                                    >
                                        {data.originalPrice}₾
                                    </s>
                                </div>
                            )}
                        </div>
                    </div>
                    <img
                        className="accordion-img"
                        alt="Chevron down"
                        src="/images/chevron-down.svg"
                    />
                </Card.Header>
            </div>
            {isOpen && (
                <Card.Body>
                    <ul
                        style={{
                            marginBottom: 0,
                        }}
                    >
                        <li>📍 შენს მისამართზე მომსახურება</li>
                        {data?._id === "66bdc79b151a5dac7867895c" && (
                            <li>🧹 მტვერსასრუტით გაწმენდა</li>
                        )}
                        {data?._id === "66bdc79b151a5dac7867895c" && (
                            <li>✨ სალონის ხსნარებით დამუშავება</li>
                        )}
                        {data?._id === "66bdc79b151a5dac7867895c" && (
                            <li>🪄 ხალიჩების გასუფთავება</li>
                        )}
                        <li>💧 ჰიდროფობიური ქაფი</li>
                        <li>⚫️ საბურავების გაშავება</li>
                        <li>🧽 ავტომობილის გაშრობა</li>
                    </ul>
                    {!isSelected && (
                        <div className="d-grid gap-2 justify-content-center mt-3">
                            <Button
                                // size="md"
                                variant="primary"
                                style={{
                                    backgroundColor: COLORS.PRIMARY.TURQOISE,
                                    borderColor: COLORS.PRIMARY.TURQOISE,
                                }}
                                onClick={() => {
                                    select();
                                    setIsOpen(false);
                                }}
                            >
                                <div className="fw-bold">არჩევა</div>
                            </Button>
                        </div>
                    )}
                </Card.Body>
            )}
        </Card>
    );
}
